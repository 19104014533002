import { useCallback, useState } from 'react';
import { ECContract__factory } from 'types/factories/ECContract__factory';
import { ERC20__factory } from 'types/factories/ERC20__factory';
import { ECContractAddress } from 'constants/address'
import { ethers } from 'ethers'
import { useSigner, useAccount } from 'wagmi'  

export const usePool = (chainId: number) => {
    const { data: signer } = useSigner();
    const { address: account } = useAccount()
    const [loading, setLoading] = useState(false)
    const [depositLoading, setDepositLoading] = useState(false)
    const [claimLoading, setClaimLoading] = useState(false)
    const [sellLoading, setSellLoading] = useState(false)
    const onApprove = useCallback(async (tokenAddress: string) => {
        try {
            if (chainId && signer && account) {
                const ecAddress = ECContractAddress[chainId];
                const contract = ERC20__factory.connect(tokenAddress, signer);
                setLoading(true);
                const tx = await contract.approve(ecAddress, ethers.constants.MaxInt256)
                const receipt = await tx.wait();
                setLoading(false);
                return receipt;
            }
        } catch (error) {
            setLoading(false);
            return false;
        }
        
    }, [chainId, signer])

    const bindInviteRelation = useCallback(async (inviter: string) => {
        try {
            if (chainId && signer) {
                const ecAddress = ECContractAddress[chainId];
                const contract = ECContract__factory.connect(ecAddress, signer);
                setLoading(true)
                const tx = await contract.bindInviteRelation(inviter);
                const receipt = await tx.wait();
                setLoading(false)
                return receipt;
            }
        } catch (error) {
            setLoading(false)
            return false;
        }
        
    }, [chainId, signer]);

    const buy = useCallback(async (typeId: string | number, inviter: string) => {
        try {
            if (chainId && signer) {
                const ecAddress = ECContractAddress[chainId];
                const contract = ECContract__factory.connect(ecAddress, signer);
                setLoading(true)
                const tx = await contract.buy(typeId, inviter);
                const receipt = await tx.wait();
                setLoading(false)
                return receipt;
            }
        } catch (error) {
            setLoading(false)
            return false;
        }
        
    }, [chainId, signer]);
    const swapYTS2YT = useCallback(async (amount: string) => {
        try {
            if (chainId && signer) {
                const ecAddress = ECContractAddress[chainId];
                const contract = ECContract__factory.connect(ecAddress, signer);
                setSellLoading(true)
                const tx = await contract.swapYTS2YT(ethers.utils.parseEther(amount));
                const receipt = await tx.wait();
                setSellLoading(false)
                return receipt;
            }
        } catch (error) {
            setSellLoading(false)
            return false;
        }
        
    }, [chainId, signer]);
    const sell = useCallback(async (amount: string, inviter: string) => {
        try {
            if (chainId && signer) {
                const ecAddress = ECContractAddress[chainId];
                const contract = ECContract__factory.connect(ecAddress, signer);
                setSellLoading(true)
                const tx = await contract.sell(ethers.utils.parseEther(amount), inviter);
                const receipt = await tx.wait();
                setSellLoading(false)
                return receipt;
            }
        } catch (error) {
            setSellLoading(false)
            return false;
        }
        
    }, [chainId, signer]);

    const deposit = useCallback(async (amount: string, inviter: string) => {
        try {
            if (chainId && signer) {
                const ecAddress = ECContractAddress[chainId];
                const contract = ECContract__factory.connect(ecAddress, signer);
                setDepositLoading(true)
                const tx = await contract.deposit(ethers.utils.parseUnits(amount, 18), inviter);
                const receipt = await tx.wait();
                setDepositLoading(false)
                return receipt;
            }
        } catch (error) {
            setDepositLoading(false)
            return false;
        }
        
    }, [chainId, signer]);

    const withdraw = useCallback(async (amount: string, inviter: string) => {
        try {
            if (chainId && signer) {
                const ecAddress = ECContractAddress[chainId];
                const contract = ECContract__factory.connect(ecAddress, signer);
                setLoading(true);
                const tx = await contract.withdraw(ethers.utils.parseUnits(amount, 18), inviter);
                const receipt = await tx.wait();
                setLoading(false);
                return receipt;
            }
        } catch (error) {
            setLoading(false);
            return false;
        }
    }, [chainId, signer]);

    const claimFarmReward = useCallback(async () => {
        try {
            if (chainId && signer && account) {
                const ecAddress = ECContractAddress[chainId];
                const contract = ECContract__factory.connect(ecAddress, signer);
                setClaimLoading(true);
                const tx = await contract.claimFarmReward(account);
                const receipt = await tx.wait();
                setClaimLoading(false);
                return receipt;
            }
        } catch (error) {
            setClaimLoading(false);
            return false;
        }
    }, [chainId, signer]);
    const claimDirectInviteReward = useCallback(async () => {
        try {
            if (chainId && signer && account) {
                const ecAddress = ECContractAddress[chainId];
                const contract = ECContract__factory.connect(ecAddress, signer);
                setClaimLoading(true);
                const tx = await contract.claimDirectInviteReward(account);
                const receipt = await tx.wait();
                setClaimLoading(false);
                return receipt;
            }
        } catch (error) {
            setClaimLoading(false);
            return false;
        }
    }, [chainId, signer]);
    const claimUmbrellaInviteReward = useCallback(async () => {
        try {
            if (chainId && signer && account) {
                const ecAddress = ECContractAddress[chainId];
                const contract = ECContract__factory.connect(ecAddress, signer);
                setClaimLoading(true);
                const tx = await contract.claimUmbrellaInviteReward(account);
                const receipt = await tx.wait();
                setClaimLoading(false);
                return receipt;
            }
        } catch (error) {
            setClaimLoading(false);
            return false;
        }
    }, [chainId, signer]);

    return { loading, depositLoading, claimLoading, sellLoading,
     onApprove, bindInviteRelation, buy, sell, swapYTS2YT, deposit, withdraw, claimFarmReward, claimDirectInviteReward, claimUmbrellaInviteReward }
}
