import { useMemo, useState } from 'react'
import { Dialog, Box, Typography, InputBase, Button, LinearProgress, CircularProgress } from '@mui/material'
import styled from '@emotion/styled'
import BigNumber from 'bignumber.js'
import { usePool } from 'hooks/usePool'

const DialogContent = styled.div`
    /* background-color: #252B32; */
    border-radius: 24px;
    padding: 25px;
    @media screen and (max-width: 900px) {
        width: 100%;
        padding: 20px
    }
`
const InputBox = styled.div`
    background: rgba(89, 97, 95, 0.10);
    backdrop-filter: blur(20px);
    border-radius: 12px;
    padding: 30px 20px;
    margin-bottom: 10px;
`
const ProgressButton = styled.div`
    background: rgba(89, 97, 95, 0.10);
    text-align: center;
    border-radius: 20px;
    padding: 4px 25px;
    color: #030F0E;
    font-size: 12px;
    cursor: pointer;
    :hover {
        background: #00DF81;
        color: #000000;
    }
    @media screen and (max-width: 900px) {
        padding: 10px;
    }
`
interface Props {
    chainId: number
    inviter: string
    balance: string
    open: boolean
    onClose: () => void
}
const UnlockModal:React.FC<Props> = ({ chainId, balance, inviter, open, onClose }) => {
    const [loading,setLoading] = useState(false)
    const [amount, setAmount] = useState('')
    const [percentage, setPercentage] = useState(0)
    const { withdraw } = usePool(chainId)
    const handleChange = (e: any) => {
        setAmount(e.target.value)
        const p = new BigNumber(e.target.value).div(balance).times(100).toNumber()
        setPercentage(p)
    }
    const handleProgressChange = (v: number) => {
        setPercentage(v)
        setAmount(new BigNumber(balance).times(v).div(100).toString())
    }
    const isCan = useMemo(() => {
        return new BigNumber(amount).lte(balance)
    }, [amount, balance])
    const handleConfirm = async () => {
        try {
            setLoading(true)
            await withdraw(amount, inviter)
            setLoading(false)
            onClose()
        } catch (error) {
            setLoading(false)
        }
    }
    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '.MuiPaper-root': {
                    borderRadius: '25px',
                    // background: '#fff'
                }
            }}
        >
            <DialogContent>
                <Box sx={{display: 'flex', justifyContent: "space-between", marginBottom: '10px'}}>
                    <Typography fontSize="20px" fontWeight="600" color="#030F0E">Unlock</Typography>
                    {/* <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <img width="30px" height="30px" src="/images/bak.svg" alt="bak" />
                        <Typography ml="4px" color="#ffffff">BAK</Typography>
                    </Box> */}
                </Box>
                <InputBox>
                    <Typography textAlign="right" fontSize="14px" color="#030F0E">
                        Balance：{balance} YT
                    </Typography>
                    <InputBase 
                        style={{
                            fontSize: '32px',
                            fontWeight: '600',
                            textAlign: 'right'
                        }} 
                    value={amount} onChange={handleChange} />
                </InputBox>
                
                <Box sx={{margin: '30px 0'}}>
                    <LinearProgress variant="determinate" value={percentage} />
                    <Box 
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4, 1fr)',
                            gridColumnGap: '10px',
                            marginTop: '14px'
                        }}>
                        <ProgressButton onClick={() => handleProgressChange(25)}>25%</ProgressButton>
                        <ProgressButton onClick={() => handleProgressChange(50)}>50%</ProgressButton>
                        <ProgressButton onClick={() => handleProgressChange(75)}>75%</ProgressButton>
                        <ProgressButton onClick={() => handleProgressChange(100)}>ALL</ProgressButton>
                    </Box>
                </Box>
                <Button  onClick={handleConfirm} disabled={!isCan} fullWidth>
                    {loading ? 'Loading' : 'Confirm'}
                </Button>
            </DialogContent>
        </Dialog>
    )
}

export default UnlockModal