import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider, Theme, darkTheme, connectorsForWallets } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig, Chain } from 'wagmi';
import { bsc, bscTestnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import merge from 'lodash.merge';
import { CssBaseline, ThemeProvider } from '@mui/material'
import { defaultLightTheme } from 'theme/default'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const myTheme = merge(darkTheme(), {
  colors: {
    accentColor: '#00DF81',
  },
} as Theme)

export const hardhat = {
  id: 1337,
  name: 'Hardhat',
  network: 'hardhat',
  nativeCurrency: {
    decimals: 18,
    name: 'hardhat',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['http://127.0.0.1:8545'] },
    default: { http: ['http://127.0.0.1:8545'] },
  },
  blockExplorers: {
    etherscan: { name: 'SnowTrace', url: 'https://bscscan.com/' },
    default: { name: 'SnowTrace', url: 'https://bscscan.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 15921452,
    },
  },
} as const satisfies Chain

const { chains, provider } = configureChains(
  [
    bsc,
    // hardhat,
    ...(process.env.NEXT_PUBLIC_ENABLE_TESTNETS === 'true' ? [bscTestnet] : []),
  ],
  [publicProvider()]
);

const { wallets } = getDefaultWallets({
  appName: 'Poop Finance',
  projectId: 'YOUR_PROJECT_ID',
  chains,
});

const connectors = connectorsForWallets([
  ...wallets,
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultLightTheme}>
      <CssBaseline />
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} theme={myTheme}>
          <App />
          <ToastContainer
            autoClose={3000}
            hideProgressBar={true}
            position='top-right'
            closeOnClick={false}
            pauseOnFocusLoss
            pauseOnHover
          />
        </RainbowKitProvider>
      </WagmiConfig>
    </ThemeProvider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
