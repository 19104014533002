import { useMemo, useState } from 'react'
import { Dialog, Box, Typography, InputBase, Button, LinearProgress, CircularProgress } from '@mui/material'
import styled from '@emotion/styled'
import { usePool } from 'hooks/usePool'
import { Token } from 'typescript'

const DialogContent = styled.div`
    /* background-color: #252B32; */
    border-radius: 24px;
    padding: 25px;
    background-color: #1D1D1D;
    @media screen and (max-width: 900px) {
        width: 100%;
        padding: 20px
    }
`
const TokenItem = styled.div<{chosed: boolean}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 200px;
    padding: 10px;
    margin: 2px 0;
    cursor: pointer;
    border-radius: 10px;
    background-color: ${({ chosed }) => chosed ? '#005142' :''};
`
interface Props {
    chainId: number
    usdtBalance: string
    mainBalance: string
    open: boolean
    onChange: (token: string) => void
    onClose: () => void
}
const ChoseTokenModal:React.FC<Props> = ({ usdtBalance, mainBalance, open, onChange, onClose }) => {
    const [token, setToken] = useState('USDT')
    const handleChangeToken = (t: string) => {
        setToken(t);
        onChange(t);
        onClose()
    }
    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '.MuiPaper-root': {
                    borderRadius: '25px',
                }
            }}
        >
            <DialogContent>
                <Box sx={{display: 'flex', justifyContent: "space-between", marginBottom: '10px'}}>
                    <Typography fontSize="20px" fontWeight="600">Select Token</Typography>
                </Box>
                <Box display="flex" flexDirection="column" mb="10px">
                    <TokenItem chosed={token === 'USDT'} onClick={() => handleChangeToken('USDT')}>
                        <Box display="flex" alignItems="center">
                            <img width="30px" height="30px" src="/images/tokens/usdt.svg" alt="USDT" />
                            <Typography color="" ml="10px">USDT</Typography>
                        </Box>
                        <Typography ml="10px">{usdtBalance}</Typography>
                    </TokenItem>
                    <TokenItem chosed={token === 'YT'} onClick={() => handleChangeToken('YT')}>
                        <Box display="flex" alignItems="center">
                            <img width="30px" height="30px" src="/images/tokens/yt.png" alt="YT" />
                            <Typography ml="10px">YT</Typography>
                        </Box>
                        <Typography ml="10px">{mainBalance}</Typography>
                    </TokenItem>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ChoseTokenModal