import { Box, Typography, Button } from '@mui/material'
import styled from "@emotion/styled";
import { DoubleImage, DoubleImageM } from 'components/Image'
import { MyInputSwap } from 'components/Input';
import { BigNumber } from 'bignumber.js';
import { useMemo, useState } from 'react';
import { usePool } from 'hooks/usePool'
import { MainTokenAddress, ChildTokenAddress } from 'constants/address'
import { IUserInfo } from 'modal/types';
import UnlockModal from 'components/Modal/UnlockModal'
import ChoseTokenModal from 'components/Modal/ChoseTokenModal';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
const SwapWrapper = styled.div`
    display: grid;
    grid-template-columns: 1.2fr 2fr;
    grid-gap: 24px;
    @media (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
`
const Card = styled.div`
   border-radius: 15px;
   background: #1D1D1D;
   padding: 10px;
`
const TokenBox = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    background: #030F0E;
`
const InputBox = styled.div`
    padding: 10px;
    background-color: rgba(89, 97, 95, 0.10);
    border-radius: 10px;
    margin-top: 10px;
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: 84px 1fr 60px;
`
const GridSend = styled.div`
    display: grid;
    grid-template-columns: 100px 1fr;
`
const StakeBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #1D1D1D;
    border-radius: 10px;
    gap: 10px;
    padding: 10px;
    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
`
const Item = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(89, 97, 95, 0.10);
    @media (max-width: 900px) {
        margin-bottom: 20px;
    }
`
interface Props {
    chainId: number
    inviter: string
    usdtBalance: string
    mainBalance: string
    childBalance: string
    mainAllowance: string
    childAllowance: string
    userInfo: IUserInfo
    childTokenPrice: string
}
const Swap: React.FC<Props> = ({
    chainId,
    inviter,
    usdtBalance,
    mainBalance,
    childBalance,
    mainAllowance,
    childAllowance,
    userInfo,
    childTokenPrice
}) => {
    const [open, setOpen] = useState(false)
    const [tokenOpen, setTokenOpen] = useState(false)
    const [token, setToken] = useState('USDT')
    const [depositAmount, setDepositAmount] = useState('')
    const [swapAmount, setSwapAmount] = useState('')
    const { deposit, onApprove, claimFarmReward, sell, swapYTS2YT,
     loading: approveLoading, depositLoading, claimLoading, sellLoading } = usePool(chainId)
    const outAamount = useMemo(() => {
        if (swapAmount !== '') {
            return token === 'USDT' ? new BigNumber(swapAmount).times(childTokenPrice).toString() :
                new BigNumber(swapAmount).times(2).toString()
        }
        return ''
    }, [swapAmount, childTokenPrice, token])
    const handleDepositApprove = async () => {
        await onApprove(MainTokenAddress[chainId])
    }
    const handleSwapApprove = async () => {
        await onApprove(ChildTokenAddress[chainId])
    }
    const handleDeposit = async () => {
        await deposit(depositAmount, inviter)
    }
    const handleCliam = async () => {
        await claimFarmReward()
    }
    const handleSell = async () => {
        if (token === 'YT') {
            await swapYTS2YT(swapAmount)
        } else {
            await sell(swapAmount, inviter)
        }
    }
    const handleSelectToken = () => {
        setTokenOpen(true)
    }
    return (
        <SwapWrapper>
            <Card>
                <TokenBox>
                    <DoubleImage />
                    <Box sx={{marginLeft: '10px'}}>
                        <Typography fontSize="20px" >Swap  YTS-USDT</Typography>
                        <Typography fontSize="12px" color="#5EAA9C">Swap YTS-USDT</Typography>
                    </Box>
                </TokenBox>
                <InputBox>
                    <Box display="flex" justifyContent="space-between" mb="10px">
                        <Typography>从</Typography>
                        <Typography>余额: {childBalance} YTS</Typography>
                    </Box>
                    <Grid>
                        <Box display="flex" alignItems="center">
                            <img width="30px" height="30px" style={{borderRadius: '50%'}} src="/images/tokens/ytc.png" alt="YTS" />
                            <Typography ml="10px">YTS</Typography>
                        </Box>
                        <MyInputSwap value={swapAmount} onChange={(val) => setSwapAmount(val)} />
                        <Button onClick={() => setSwapAmount(childBalance)} variant='text' style={{fontWeight: '600'}}>ALL</Button>
                    </Grid>
                </InputBox>
                <InputBox>
                    <Box display="flex" justifyContent="space-between" mb="10px">
                        <Typography>收到</Typography>
                        <Typography>余额: {token === "USDT" ?  usdtBalance : mainBalance} {token}</Typography>
                    </Box>
                    <GridSend>
                        <Box onClick={handleSelectToken} display="flex" alignItems="center" style={{cursor: 'pointer'}}>
                            {token === 'USDT' ?
                                <img width="30px" height="30px" src="/images/tokens/usdt.svg" alt="USDT" /> :
                                <img width="30px" height="30px" src="/images/tokens/yt.png" alt="YT" />
                            }
                            <Typography ml="10px">{token}</Typography>
                            <ExpandMoreOutlinedIcon style={{color: '#00DF81'}} />
                        </Box>
                        <MyInputSwap disabled value={outAamount} />
                    </GridSend>
                </InputBox>
                {
                    new BigNumber(swapAmount).gt(childAllowance) ? 
                    <Button disabled={approveLoading} onClick={handleSwapApprove} style={{width: '100%', fontWeight: '600', height: '40px'}}>
                        {approveLoading ? 'Loading' : 'Approve'}
                    </Button> :
                    <Button onClick={handleSell} disabled={swapAmount === '' || sellLoading || new BigNumber(swapAmount).gt(childBalance)} style={{width: '100%', fontWeight: '600', height: '40px'}}>
                        {swapAmount === '' ? '请输入' : sellLoading ? 'Loading' : 'Swap'}
                    </Button>
                }
            </Card>
            <StakeBox>
                <Box display="flex" flexDirection="column">
                    <TokenBox>
                        <DoubleImageM />
                        <Box sx={{marginLeft: '10px'}}>
                            <Typography fontSize="20px" >YT-USDT</Typography>
                            <Typography fontSize="12px" color="#5EAA9C">Liquidity Pool</Typography>
                        </Box>
                    </TokenBox>
                    <Box display="flex" flexDirection="column" m="24px 0" p="0 10px">
                        <Typography>APR</Typography>
                        <Typography color="#00DF81" fontWeight="600" fontSize="16px">1.3‰ / Day</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        background: 'rgba(89, 97, 95, 0.10)',
                        padding: '10px',
                        borderRadius: '10px',
                        mb: "20px",
                    }}
                    >
                        <Typography textAlign="right" mb="10px">余额: {mainBalance} YT</Typography>
                        <Box display="flex">
                            <MyInputSwap value={depositAmount} onChange={(value) => setDepositAmount(value)} />
                            <Button onClick={() => {setDepositAmount(mainBalance)}} variant='text' style={{fontWeight: '600'}}>ALL</Button>
                        </Box>
                    </Box>
                    {
                        new BigNumber(depositAmount).gt(mainAllowance) ? 
                        <Button onClick={handleDepositApprove} style={{width: '100%', fontWeight: '600', height: '40px'}}>
                            Approve
                        </Button> :
                        <Button onClick={handleDeposit} disabled={depositAmount === '' || depositLoading || new BigNumber(depositAmount).gt(mainBalance)} style={{width: '100%', fontWeight: '600', height: '40px'}}>
                            {depositAmount === '' ? '请输入' : depositLoading ? 'Loading' : 'Stake'}
                        </Button>
                    }
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        flexDirection: "column",
                        justifyContent: 'space-evenly',
                        padding: '0 10px'
                    }}
                >
                    <Item>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Typography >YT质押数</Typography>
                            <Typography fontSize="16px" color="#00DF81" fontWeight="600">{userInfo.depositAmount} YT</Typography>
                        </Box>
                        <Button 
                            disabled={new BigNumber(userInfo.depositAmount).lte(0)}
                            onClick={() =>  setOpen(true)}
                            sx={{
                                borderRadius: '40px',
                                fontWeight: '600'
                            }}
                        >
                            Unlocke
                        </Button>
                    </Item>
                    <Item>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Typography >已领取质押收益</Typography>
                            <Typography fontSize="16px" color="#00DF81" fontWeight="600">{userInfo.claimedFarmReward} YTS</Typography>
                        </Box>
                    </Item>
                    <Item>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Typography >质押收益</Typography>
                            <Typography fontSize="16px" color="#00DF81" fontWeight="600">{userInfo.pendingFarmReward} YTS</Typography>
                        </Box>
                        <Button
                            disabled={new BigNumber(userInfo.pendingFarmReward).lte(0) || claimLoading}
                            onClick={handleCliam}
                            sx={{
                                borderRadius: '40px',
                                fontWeight: '600'
                            }}
                        >
                            {claimLoading ? 'Loading' : 'Claim'}
                        </Button>
                    </Item>
                </Box>
            </StakeBox>
            <UnlockModal
                open={open}
                chainId={chainId}
                inviter={inviter}
                balance={userInfo.depositAmount}
                onClose={() => setOpen(false)}
            />
            <ChoseTokenModal
                open={tokenOpen}
                chainId={chainId}
                usdtBalance={usdtBalance}
                mainBalance={mainBalance}
                onChange={(t) => setToken(t)}
                onClose={() => setTokenOpen(false)}
            />
        </SwapWrapper>
    )
}

export default Swap;