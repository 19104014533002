import styled from 'styled-components'

const StyleInput = styled.div`
    display: flex;
    width: 100%;
    input {
        width: 100%;
        outline-style: none;
        border: 0px;
        padding: 7px 10px;
        background-color: #1D1D1D;
        border-radius: 6px;
        height: 36px;
        color: #5EAA9C;
        :focus {
            border: 0px;
        }
    }
    input::-webkit-input-placeholder {
        color: #5EAA9C;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        margin: 0; 
    }
    input[type=number] {
        -moz-appearance:textfield;
    }
`
const StyleInputSwap = styled.div`
    width: 100%;
    input {
        width: 100%;
        outline-style: none;
        border: 0px;
        padding: 7px 10px;
        background-color: #1D1D1D;
        border-radius: 6px;
        font-size: 24px;
        font-weight: 600;
        color: #ffffff;
        /* text-align: right; */
        :focus {
            border: 0px;
        }
    }
    input::-webkit-input-placeholder {
        color: #5EAA9C;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        margin: 0; 
    }
    input[type=number] {
        -moz-appearance:textfield;
    }
`
interface Props {
    disabled?: boolean
    value?: string,
    onChange?:(val: string) => void 
}
export const MyInputSwap:React.FC<Props> = ({ value, onChange }) => {
    return (
        <StyleInputSwap>
            <input type='number' value={value} onChange={(e) => onChange && onChange(e.target.value)} />
        </StyleInputSwap>
    )
}
const MyInput:React.FC<Props> = ({ value, onChange, disabled }) => {
    return (
        <StyleInput>
            <input disabled={disabled} value={value} onChange={(e) => onChange && onChange(e.target.value)} />
        </StyleInput>
    )
}

export default MyInput;