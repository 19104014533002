import styled from "@emotion/styled";
import { Typography, Box } from '@mui/material'
import dayjs from "dayjs";
import { IHistory } from "modal/types";
const StyleHistory = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 18px;
    background: #1D1D1D;
    padding: 30px 20px;
`
const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.04);
`
interface Props {
    history: IHistory []
}
const History:React.FC<Props> = ({ history }) => {
    return (
        <StyleHistory>
            <Typography variant="subtitle1">History</Typography>
            {
                !!history?.length ? 
                history.map((d, i) => (
                    <ListItem key={i}>
                        <img src='/images/bike_left.svg' width="100px" height="100px"/>
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <Typography variant="subtitle1">YT Amount</Typography>
                            <Typography>{d.costAmount} YT</Typography>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <Typography variant="subtitle1">Time</Typography>
                            <Typography>{dayjs.unix(Number(d.timestamp)).format('YYYY-MM-DD HH:mm')}</Typography>
                        </Box>
                    </ListItem>
                )) : 
                <Box height="100px" display="flex" justifyContent="center" alignItems='center'>
                    <Typography>No History</Typography>
                </Box>
            }
        </StyleHistory>
    )
}

export default History;