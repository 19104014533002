export const ECContractAddress: {[x in number]: string} = {
    [56]: '0x444720cEaF969894C6fBdB368122f0ebEBCd83D6',
    [1337]: '0x0DCd1Bf9A1b36cE34237eEaFef220932846BCD82'
}

export const MainTokenAddress: {[x in number]: string} = {
    [56]: '0x1d275965727892bd48A0F6AcfCE7aDB3cCa849f6',
    [1337]: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0'
}

export const ChildTokenAddress: {[x in number]: string} = {
    [56]: '0x5086Da9C8E5846Ae71758C5Fc610FE528Aff8dDe',
    [1337]: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9'
}

export const USDTAddress: {[x in number]: string} = {
    [56]: '0x55d398326f99059fF775485246999027B3197955',
    [1337]: '0x5FC8d32690cc91D4c39d9d3abcBD16989F875707'
}

// export const ECContractAddress = '0x1eb6700120D358BAc05edfB95C89759AEF2e6550'

// export const MainTokenAddress = '0x32BD28d3eA218d4E18776d29bb4e955f5c4E9e7F'

// export const ChildTokenAddress = '0xe4b44cA0DBe581B681bF59eB630b975757612253'

// export const USDTAddress = '0x5341f6C02B5cAbCF54b89CdC631B05796D4bd95f'

//

// export const ECContractAddress = '0x0DCd1Bf9A1b36cE34237eEaFef220932846BCD82'

// export const MainTokenAddress = '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0'

// export const ChildTokenAddress = '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9'

// export const USDTAddress = '0x5FC8d32690cc91D4c39d9d3abcBD16989F875707'