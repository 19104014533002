import styled from "@emotion/styled";

export const Container = styled.div`
    padding: 0 2rem;
    position: relative;
    @media (max-width: 1200px) {
        padding: 0;
    }
`

export const Background = styled.div`
    display: flex;
    justify-content: flex-end;
    > img:nth-child(2) {
        display: none;
    }
    @media (max-width: 900px) {
        > img:nth-child(1) {
            display: none;
        }
        > img:nth-child(2) {
            display: block;
        }
    }
`

export const Main = styled.div`
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 60px;
    min-height: 100vh;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    padding-bottom: 50px;
    width: 1200px;
    @media (max-width: 1200px) {
        width: 100%;
        padding: 0 10px;
    }
`
export const Banner = styled.div`
    position: relative;
    width: 70%;
    img {
        position: absolute;
        top: -42px;
        left: -55px
    }
    @media (max-width: 1200px) {
        width: 100%;
        img {
            display: none;
        }
    }
    
`
export const Solgo = styled.div`
    color: #00DF81;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
`
export const Description = styled.div`
    margin-top: 10px;
    line-height: 16px;
    font-size: 14px;
    color: #fff;
    text-align: left;
`