import styled from "@emotion/styled";
import { Typography, Box, Button } from '@mui/material' 
import BigNumber from "bignumber.js";
import { IUserInfo } from "modal/types";
import { usePool } from 'hooks/usePool'
const StyleLevel = styled.div`
    display: flex;
    align-items: center;
    border-radius: 15px;
    padding: 20px;
    background: linear-gradient(270deg, #005142 0%, rgba(0, 223, 129, 0.04) 100%);
    gap: 70px;
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
`
const LevelBox = styled.div`
    width: 190px;
    height: 135px;
    background: url('/images/level.svg');
    position: relative;
`
const LevelNum = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(50% - 10px);
    color: #FFB15C;
    font-size: 36px;
    font-weight: 600;
`
const List = styled.div`
    display: flex;
    flex-direction: column;
    flex: auto;
    @media (max-width: 600px) {
        width: 100%;
    }
`
const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #005142;
    margin-bottom: 20px;
`
interface Props {
    chainId: number
    childBalance: string
    userInfo: IUserInfo
}
const Level:React.FC<Props> = ({ userInfo, childBalance, chainId }) => {
    const { claimDirectInviteReward, claimUmbrellaInviteReward, claimLoading } = usePool(chainId)
    const handleDirectInviteClaim = async () => {
        await claimDirectInviteReward()
    }
    const handleUmbrellaInviteClaim = async () => {
        await claimUmbrellaInviteReward()
    }
    return (
        <StyleLevel>
            <LevelBox>
                <LevelNum>A{userInfo.vipLevel}</LevelNum>
            </LevelBox>
            <List>
                <ListItem>
                    <Typography variant="subtitle1">YTS 余额</Typography>
                    <Typography>{childBalance} YTS</Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="subtitle1">总业绩 </Typography>
                    <Typography >{new BigNumber(userInfo.directAmount).plus(userInfo.umbrellaAmount).toString()} USDT</Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="subtitle1">直推收益</Typography>
                    <Box display="flex" alignItems="center">
                        <Typography>{userInfo.pendingDirectInviteReward} USDT</Typography>
                        <Button
                            onClick={handleDirectInviteClaim}
                            disabled={new BigNumber(userInfo.pendingDirectInviteReward).lte(0) || claimLoading} 
                            sx={{ml: '30px'}}>
                            {claimLoading ? 'Loading' : 'Claim'}
                        </Button>
                    </Box>
                </ListItem>
                <ListItem>
                    <Typography variant="subtitle1">级别收益</Typography>
                    <Box display="flex" alignItems="center">
                        <Typography>{userInfo.pendingUmbrellaInviteReward} USDT</Typography>
                        <Button
                            onClick={handleUmbrellaInviteClaim}
                            disabled={new BigNumber(userInfo.pendingUmbrellaInviteReward).lte(0) || claimLoading} 
                            sx={{ml: '30px'}}>
                            {claimLoading ? 'Loading' : 'Claim'}
                        </Button>
                    </Box>
                </ListItem>
            </List>
        </StyleLevel>
    )
}

export default Level;