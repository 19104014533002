import { Box } from '@mui/material'

export const DoubleImageM = () => {
    return (
        <Box>
            <img src="/images/tokens/yt.png" width="58px" height="58px" style={{borderRadius: '50%'}} alt="YT" />
            <img src="/images/tokens/usdt.svg" alt="USDT" style={{marginLeft: '-20px'}} />
        </Box>
    )
}
export const DoubleImage = () => {
    return (
        <Box>
            <img src="/images/tokens/ytc.png" width="58px" height="58px" style={{borderRadius: '50%'}} alt="YT" />
            <img src="/images/tokens/usdt.svg" alt="USDT" style={{marginLeft: '-20px'}} />
        </Box>
    )
}

export const DoubleImage2 = () => {
    return (
        <Box>
            <img src="/images/tokens/yt.png" width="58px" height="58px" style={{borderRadius: '50%'}} alt="YT" />
            <img src="/images/tokens/usdt.svg" alt="USDT" width="30" height="30px" style={{marginLeft: '-20px'}} />
        </Box>
    )
}


