import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Container, Background, Main, Banner, Solgo, Description } from 'styles/home'
// import styles from '../styles/Home.module.css';
import Invite from 'components/Invite'
import Swap from 'components/Swap'
import Exchange from 'components/Exchange';
import Level from 'components/Level';
import History from 'components/History';
import { useNetwork, useAccount, useContractReads } from 'wagmi'
// import ERC20ABI from 'abi/ERC20.json'
import { ECContract__factory } from 'types/factories/ECContract__factory'
import { ERC20__factory } from 'types/factories/ERC20__factory'
import { ECContractAddress, USDTAddress, MainTokenAddress, ChildTokenAddress } from 'constants/address'
import { useEffect, useMemo, useState } from 'react';
import { ethers } from 'ethers';
import { IUserInfo, IBuyTypes, IHistory } from 'modal/types'

const Home = () => {
  const [pool, setPool] = useState<{
    usdtBalance: string
    mainBalance: string
    childBalance: string
    rootInviter: string
    childTokenPrice: string
    allowance: string
    mainAllowance: string
    childAllowance: string
    userInfo: IUserInfo,
    buyTypes: IBuyTypes []
    historys: IHistory []
  }>({
    usdtBalance: '0',
    mainBalance: '0',
    childBalance: '0',
    rootInviter: '',
    childTokenPrice: '',
    allowance: '0',
    mainAllowance: '0',
    childAllowance: '0',
    userInfo: {
      inviter: '',
      vipLevel: '',
      depositAmount: '',
      directAmount: '',
      umbrellaAmount: '',
      pendingFarmReward: '',
      // pendingInviteReward: '',
      pendingDirectInviteReward: '',
      pendingUmbrellaInviteReward: '',
      // claimedInviteReward: '',
      claimedDirectInviteReward: '',
      claimedUmbrellaInviteReward: '',
      claimedFarmReward: ''
    },
    buyTypes: [],
    historys: []
  })
  const { chains, chain } = useNetwork()
  const { address, isConnecting, isDisconnected } = useAccount()
  const account = address || '0xffffffffffffffffffffffffffffffffffffffff' as `0x${string}`
  // const account = '0xBE821661C2A7BfD41f9fD4A74026B906FD365744'
  const chainId = chain?.id || 56
  const { data, error, isLoading } = useContractReads({
    contracts: [
      {
        address: USDTAddress[chainId] as `0x${string}`,
        abi: ERC20__factory.abi,
        functionName: 'balanceOf',
        args: [account],
        chainId: chain?.id,
      },
      {
        address: MainTokenAddress[chainId] as `0x${string}`,
        abi: ERC20__factory.abi,
        functionName: 'balanceOf',
        args: [account],
        chainId: chain?.id,
      },
      {
        address: ChildTokenAddress[chainId] as `0x${string}`,
        abi: ERC20__factory.abi,
        functionName: 'balanceOf',
        args: [account],
        chainId: chain?.id
      },
      {
        address: ECContractAddress[chainId] as `0x${string}`,
        abi: ECContract__factory.abi,
        functionName: 'rootInviter',
        chainId: chain?.id
      },
      {
        address: ECContractAddress[chainId] as `0x${string}`,
        abi: ECContract__factory.abi,
        functionName: 'getBuyTypes',
        chainId: chain?.id
      },
      {
        address: ECContractAddress[chainId] as `0x${string}`,
        abi: ECContract__factory.abi,
        functionName: 'tokenPrice',
        args: [ChildTokenAddress[chainId] as `0x${string}`],
        chainId: chain?.id
      },
      {
        address: ECContractAddress[chainId] as `0x${string}`,
        abi: ECContract__factory.abi,
        functionName: 'getUserInfo',
        args: [account],
        chainId: chain?.id
      },
      {
        address: ECContractAddress[chainId] as `0x${string}`,
        abi: ECContract__factory.abi,
        functionName: 'getUserBuyHistory',
        args: [account],
        chainId: chain?.id
      },
      {
        address: USDTAddress[chainId] as `0x${string}`,
        abi: ERC20__factory.abi,
        functionName: 'allowance',
        args: [account, ECContractAddress[chainId] as `0x${string}`],
        chainId: chain?.id
      },
      {
        address: MainTokenAddress[chainId] as `0x${string}`,
        abi: ERC20__factory.abi,
        functionName: 'allowance',
        args: [account, ECContractAddress[chainId] as `0x${string}`],
        chainId: chain?.id
      },
      {
        address: ChildTokenAddress[chainId] as `0x${string}`,
        abi: ERC20__factory.abi,
        functionName: 'allowance',
        args: [account, ECContractAddress[chainId] as `0x${string}`],
        chainId: chain?.id
      },
    ],
    watch: true
  })
  useEffect(() => {
    const results = data as any []
    // console.log("results", results)
    if (results && results[0]) {
      const usdtBalance = ethers.utils.formatEther(results[0])
      const mainBalance = ethers.utils.formatEther(results[1])
      const childBalance = ethers.utils.formatEther(results[2])
      const rootInviter = results[3]
      const allowance = ethers.utils.formatEther(results[8])
      const mainAllowance = ethers.utils.formatEther(results[9])
      const childAllowance = ethers.utils.formatEther(results[10])
      const childTokenPrice = ethers.utils.formatEther(results[5])
      const buyTypes: IBuyTypes[] = results[4].map((d: any) => ({
        typeId: d.typeId.toString(),
        amount: ethers.utils.formatEther(d.amount)

      }))
      const userInfo: IUserInfo = {
        inviter: results[6].inviter,
        vipLevel: results[6].vipLevel,
        depositAmount: ethers.utils.formatEther(results[6].depositAmount),
        directAmount: ethers.utils.formatEther(results[6].directAmount),
        umbrellaAmount: ethers.utils.formatEther(results[6].umbrellaAmount),
        pendingFarmReward: ethers.utils.formatEther(results[6].pendingFarmReward),
        // pendingInviteReward: ethers.utils.formatEther(results[6].pendingInviteReward),
        pendingDirectInviteReward: ethers.utils.formatEther(results[6].pendingDirectInviteReward),
        pendingUmbrellaInviteReward: ethers.utils.formatEther(results[6].pendingUmbrellaInviteReward),
        claimedFarmReward: ethers.utils.formatEther(results[6].claimedFarmReward),
        claimedDirectInviteReward: ethers.utils.formatEther(results[6].claimedDirectInviteReward),
        claimedUmbrellaInviteReward: ethers.utils.formatEther(results[6].claimedUmbrellaInviteReward),
        // claimedInviteReward: ethers.utils.formatEther(results[6].claimedInviteReward),
      }
      const historys: IHistory[] = results[7].map((d: any) => ({
        costAmount: ethers.utils.formatEther(d.costAmount.toString()),
        receiveAmount: ethers.utils.formatEther(d.receiveAmount.toString()),
        timestamp: d.timestamp.toString()
      }))
      setPool({
        usdtBalance,
        mainBalance,
        childBalance,
        rootInviter,
        childTokenPrice,
        allowance,
        mainAllowance,
        childAllowance,
        buyTypes,
        userInfo,
        historys
      })
    }
  }, [data])
  const inviter = useMemo(()=> {
    return pool.userInfo?.inviter !== '0x0000000000000000000000000000000000000000' ? pool.userInfo.inviter : pool.rootInviter
  }, [pool])

  
  return (
    <Container>
      <Background>
        <img src="images/background.svg" alt="" />
        <img src="images/background_m.svg" alt="" />
      </Background>
      <Main>
        <ConnectButton />
        <Banner>
          <Solgo>
            Dream it. Build it. Grow it.
          </Solgo>
          <Description>
            Whatever your vision—a SaaS app, an AI/ML business, a website, an eCommerce store—build it here using DigitalOcean's simple, cost-effective cloud hosting services.
          </Description>
          <Invite 
            inviter={inviter}
            rootInviter={pool.rootInviter}
            userInfo={pool.userInfo}
            account={account}
            contract={ECContractAddress[chainId]}
          />
          <img src="/images/arrow.svg" />
        </Banner>
        <Swap
          chainId={chainId}
          inviter={inviter}
          usdtBalance={pool.usdtBalance}
          mainBalance={pool.mainBalance}
          childBalance={pool.childBalance}
          mainAllowance={pool.mainAllowance}
          childAllowance={pool.childAllowance}
          userInfo={pool.userInfo}
          childTokenPrice={pool.childTokenPrice}
        />
        <Exchange buytypes={pool.buyTypes} allowance={pool.allowance} chainId={chainId} inviter={inviter}/>
        <Level
           chainId={chainId}
           childBalance={pool.childBalance}
           userInfo={pool.userInfo}
        />
        <History history={pool.historys} />
      </Main>
    </Container>
  );
};

export default Home;
