import { Typography, Button, Box } from '@mui/material'
import styled from "styled-components"
import MyInput from 'components/Input'
import { IUserInfo } from 'modal/types'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import copy from "copy-to-clipboard";
import { toast } from 'react-toastify'
import { usePool } from 'hooks/usePool'
import { ethers } from 'ethers'
const Grid = styled.div`
    display: flex;
    grid-gap: 10px;
    align-items: center;
    padding: 6px 14px;
    border-radius: 6px;
    background: #030F0E;
`
const SuperiorBox = styled(Grid)`
    margin-top: 50px;
    grid-template-columns: 60px auto 100px;
    margin-bottom: 20px;
`
const InviteBox = styled(Grid)`
    grid-template-columns: 90px auto 32px;
`
interface Props {
    inviter: string
    account: string
    contract: string
    rootInviter: string
    userInfo: IUserInfo
}
const Invied: React.FC<Props> = ({ rootInviter, inviter, userInfo, account, contract }) => {
    const [ params ] = useSearchParams();
    const invite = params.getAll('code')[0] ?? window.localStorage.getItem('invite');
    const [address, setAddress] = useState<string>(inviter)
    const [isInvited, setIsInvited] = useState(false)
    const [myset, setMyset] = useState(false)
    const { bindInviteRelation } = usePool(56)
    const handleChange = (val: string) => {
        setAddress(val)
        setMyset(true)
    }
    useEffect(() => {
        if (userInfo.inviter === '0x0000000000000000000000000000000000000000') {
            const inviteAddress = invite || rootInviter
            if (!myset) {
                setAddress(inviteAddress)
            }
            setIsInvited(false)
        } else {
            if (!myset) {
                setAddress(userInfo.inviter)
            }
            setIsInvited(true)
        }
        
    }, [userInfo, rootInviter, invite])
    // const handleCopy = (cp: string | null | undefined) => {
    //     if (!cp) return  
    //     const res = copy(cp)
    //     if (res) {
    //         toast.success('Copied!')
    //     }
    // }
    const handleBind = async () => {
        try {
            if (!ethers.utils.isAddress(address as `0x${string}`)) {
                toast.success('Address is illegal')
            } else if (address) {
                await bindInviteRelation(address)
            } else {
                toast.success('Input inviter address')
            }
        } catch (error) {
            
        }
    }
    return (
        <>
            <SuperiorBox>
                <Typography style={{ whiteSpace: 'nowrap'}}>我的邀请人</Typography>
                <MyInput value={address} onChange={handleChange} />
                {isInvited ? <Typography variant='subtitle1' style={{ whiteSpace: 'nowrap'}}>已绑定</Typography> : 
                    <Button onClick={handleBind}>链接</Button>
                }
            </SuperiorBox>
            {/* <InviteBox>
                <Typography style={{ whiteSpace: 'nowrap'}}>我的邀请地址</Typography>
                <MyInput value={`https://www.yate.pro?invite=${account}`} disabled />
                <Box sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} 
                    onClick={() => handleCopy(`https://www.yate.pro?invite=${account}`)}>
                    <img src="/images/Copy.svg" alt="Copy" width="32px" height="32px" />
                </Box>
            </InviteBox> */}
        </>
    )
}
export default Invied