import { createTheme, Theme } from '@mui/material';

interface TypographyCustomVariants {
    text1: React.CSSProperties
    desc20Medium: React.CSSProperties
    desc14Book: React.CSSProperties
    desc16Book: React.CSSProperties
    desc16Medium: React.CSSProperties
    desc14MediumWhite: React.CSSProperties
    tooltip: React.CSSProperties
}

declare module '@mui/material/styles' {
    // interface TypographyVariants extends TypographyCustomVariants {}
  
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions extends TypographyCustomVariants {}
  
    interface BreakpointOverrides {
      xsm: true
      xxl: true
    }
  }
declare module "@mui/system/createTheme/createBreakpoints" {
    interface BreakpointOverrides {
        xsm: true
        xxl: true
    }
}
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
      text1: true
    //   desc20Medium: true
    //   desc14Book: true
    //   desc16Book: true
    //   tooltip: true
    //   desc16Medium: true
    //   desc14MediumWhite: true
    }
}
export const defaultLightTheme: Theme = createTheme({
    palette: {
        primary: {
          main: '#00DF81',
        },
        text: {
            disabled: '#030F0E'
        },
    },
    breakpoints: {
        keys: ['xs', 'xsm', 'sm', 'md', 'lg', 'xl', 'xxl'],
        values: { xs: 0, xsm: 640, sm: 760, md: 960, lg: 1280, xl: 1575, xxl: 1800 },
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'contained'
            },
            styleOverrides: {
                root: {
                    height: '40px',
                    borderRadius: '10px',
                    textTransform: 'none',
                    fontWeight: 700,
                    '&:disabled': {
                        backgroundColor: '#005142',
                        color: '#5EAA9C',
                    },
                }
            }
        },
        MuiTypography: ({
            defaultProps: {
                variant: 'body1'
            },
            styleOverrides: {
                body1: {
                   fontSize: '14px',
                   fontWeight: 400,
                   color: '#FFFFFF'
                },
                subtitle1: {
                    color: '#5EAA9C',
                    fontSize: '12px',
                    fontWeight: 400,
                },
            }
        }),
        MuiCircularProgress: ({
            styleOverrides: {
                root: {
                    color: '#030F0E'
                }
            }
        }),
        MuiDialog: ({
            styleOverrides: {
                paper: {
                    borderRadius: '25px',
                    margin: '0',
                }
            }
        }),
        MuiCssBaseline: ({
            styleOverrides: {
                body: {
                    fontFamily: 'PingFang SC',
                    backgroundColor: '#131313',
                },
                a: {
                    textDecoration: 'none',
                    color: 'inherit',
                },
                '.Mui-disabled': {
                    backgroundColor: '!important #0a412a'
                },
                // '.Toastify__toast-container': {
                //     minWidth: '360px',
                //     '@media only screen and (max-width: 600px)': {
                //         width: '80% !important',
                //         right: 0,
                //     }
                // },
                // '.Toastify__toast-body': {
                //     padding: 0,
                //     width: '134vm',
                // },
                // '.Toastify__toast': {
                //     background: '#DAD4C2',
                //     borderRadius: '30px',
                //     padding: 0
                // },
                // '.Toastify__close-button': {
                //     display: 'none'
                // }
            }
        })
    }
});