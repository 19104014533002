import { Box, Typography, Button } from '@mui/material'
import styled from 'styled-components'
import { DoubleImage2 } from 'components/Image'
import { IBuyTypes } from 'modal/types'
import { usePool } from 'hooks/usePool'
import { USDTAddress } from 'constants/address'
import BigNumber from 'bignumber.js'
const ExchangeWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    @media (max-width: 1100px) {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
`
const Card = styled.div`
    display: flex;
    flex-direction: column;
`
const CardTop = styled.div`
    display: flex;
    padding: 30px 20px;
    border-radius: 15px 15px 0 0;
    background-color: #005142;
`
const CardTopImgLeft = styled(CardTop)`
    background: url('/images/bike_right.svg') #005142 no-repeat right center;
    background-position: 100% 50%;
`
const CardTopImgRight = styled(CardTop)`
    background: url('/images/bike.svg') no-repeat,#005142;
    background-position: 120% center;
`
const CardBottom = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    border-radius: 0 0 15px 15px;
    background-color: #1D1D1D;
`
const Tag = styled.div`
    display: inline-block;
    padding: 2px 10px;
    color: #030F0E;
    font-family: PingFang SC;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    border-radius: 40px;
    background-color: #ffffff;
`
interface Props {
    buytypes: IBuyTypes[]
    allowance: string,
    chainId: number,
    inviter: string
}
interface CardProps {
    inviter: string
    chainId: number
    type: IBuyTypes
    allowance: string
    index: number
}
const RenderCard:React.FC<CardProps> = ({ type, allowance, chainId, inviter, index }) => {
    const { onApprove, buy, loading } = usePool(chainId)
    const handleApprove = async () => {
        await onApprove(USDTAddress[chainId])
    }
    const handleBuy = async (typeId: string | number) => {
        await buy(typeId, inviter)
    }
    return (
        <Card>
            {
                index < 3 &&
                <CardTop>
                    <DoubleImage2 />
                    <Box ml="10px">
                    <Typography fontSize="24px" fontWeight="700" color="#ffffff">{type.amount} USDT</Typography> 
                    {/* <Typography fontSize="12px" fontWeight="400" color="#5EAA9C">质押USDT得YT</Typography> */}
                    <Tag>1USDT=0.9YT</Tag>
                    </Box>
                </CardTop>
            }
            {
                index === 3 &&
                <CardTopImgRight>
                    <DoubleImage2 />
                    <Box ml="10px">
                    <Typography fontSize="24px" fontWeight="700" color="#ffffff">{type.amount} USDT</Typography> 
                    {/* <Typography fontSize="12px" fontWeight="400" color="#5EAA9C">质押USDT得YT</Typography> */}
                    <Tag>1USDT=0.9YT</Tag>
                    </Box>
                </CardTopImgRight>
            }
            
            <CardBottom>
                {new BigNumber(type.amount).gt(allowance) ?
                    <Button sx={{fontWeight: '700'}} onClick={handleApprove}>{loading ? 'Loading...' : '授权'}</Button> :
                    <Button sx={{fontWeight: '700'}} onClick={() => handleBuy(type.typeId)}>{loading ? 'Loading...' : `获取 ${type.amount} YT`}</Button>
                }
            </CardBottom>
        </Card>
    )
}
const Exchange:React.FC<Props> = ({ buytypes, allowance, chainId, inviter }) => {
   
    return (
        <ExchangeWrapper>
            {
                buytypes.map((d, i)=> (
                <RenderCard
                    key={d.typeId}
                    index={i}
                    chainId={chainId}
                    type={d}
                    allowance={allowance}
                    inviter={inviter}
                />))
            }
        </ExchangeWrapper>
    )
}

export default Exchange